import React from "react";
import "../index.css";

import Button from "../../Button";

const TryFreeTrial: React.FC = () => (
  <div className="try-free-trial-new-online-wrapper px-3 md:px-0">
    <div className="try-free-trial-new-online text-3xl lg:text-5xl">
      deeple ระบบจัดการร้านค้าออนไลน์ ที่รู้ใจทุกธุรกิจ
      <br />
      บน Social Commerce
    </div>
    <div className="try-free-trial-button">
      <a href="https://console.deeple.ai/register">
        <Button
          className="px-10 text-xl"
          variant="gradient-white"
          size="medium"
        >
          สมัครใช้งานฟรี
        </Button>
      </a>
    </div>
  </div>
);

export default TryFreeTrial;
