import { PageProps } from "gatsby";

import CampaignChatbot from "../../../components/CampaignChatbot/New-online";
import withPages from "../../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(CampaignChatbot)({
    title: "deeple ระบบจัดการร้านค้าออนไลน์ ใช้งานบน Facebook, LINE OA และ IG",
    description:
      "deeple ระบบจัดการร้านค้าออนไลน์ มาพร้อมระบบออเดอร์ สต็อก แชทบอทปิดการขาย ระบบดูดคอมเมนต์ไลฟ์ ใช้งานบน Facebook, LINE OA และ IG",
    ...props,
  });
}

export default IndexPage;
