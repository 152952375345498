import React from "react";
import "./featureList.css";
import Button from "../../Button";

import Feature1 from "../../../../static/icon/campaign/feature-1.svg";
import Feature2 from "../../../../static/icon/campaign/feature-2.svg";
import Feature3 from "../../../../static/icon/campaign/feature-3.svg";
import Feature4 from "../../../../static/icon/campaign/feature-4.svg";
import HeaderImage from "../../../../static/image/deeple-store-management-bg.jpg";

const featureListDetails = [
  {
    id: 0,
    header: "ฟีเจอร์ซื้อ-ขายผ่านแชท",
    imageComponent: (
      <img
        src={Feature1}
        loading="lazy"
        height="60"
        width="60"
        alt=""
        className="mb-7 feature-icon"
      />
    ),
    details: [
      {
        data: "• เลือกวิธีซื้อผ่านแชท หรือเว็บช็อปปิ้ง",
        id: 0,
        isShowOnlyDesktop: true,
      },
      {
        data: "• AI ช่วยกรอกที่อยู่อัตโนมัติ",
        id: 1,
      },
      {
        data: "• AI เสนอโปรโมชันอัตโนมัติ",
        id: 2,
      },
      {
        data: "• สอน AI ตอบคำถามเพิ่ม 30 คำถาม",
        id: 3,
        isShowOnlyDesktop: true,
      },
      {
        data: "• ระบบดูดคอมเมนต์จาก Facebook Live",
        id: 4,
      },
    ],
  },
  {
    id: 1,
    header: "ระบบจัดการร้านค้า",
    imageComponent: (
      <img
        src={Feature2}
        loading="lazy"
        height="60"
        width="60"
        alt=""
        className="mb-7 feature-icon"
      />
    ),
    details: [
      {
        data: "• รวมแชทจากทุกแพลตฟอร์ม",
        id: 0,
      },
      {
        data: "• ระบบช่วยจัดการออเดอร์และสต็อก",
        id: 1,
      },
      {
        data: "• รายงานการขายและพฤติกรรมลูกค้า",
        id: 2,
        isShowOnlyDesktop: true,
      },
      {
        data: "• บรอดแคสต์ผ่าน FB, LINE OA",
        id: 3,
      },
      {
        data: "• ระบบจัดกลุ่มลูกค้าตามความสนใจ",
        id: 4,
        isShowOnlyDesktop: true,
      },
      {
        data: "• ระบบจัดการธีมร้าน",
        id: 5,
        isShowOnlyDesktop: true,
      },
      {
        data: "• ระบบออเดอร์ออฟไลน์",
        id: 6,
        isShowOnlyDesktop: true,
      },
    ],
  },
  {
    id: 2,
    header: "ติดตั้งและใช้งานง่าย",
    imageComponent: (
      <img
        src={Feature3}
        loading="lazy"
        height="60"
        width="60"
        alt=""
        className="mb-7 feature-icon"
      />
    ),
    details: [
      {
        data: "• ติดตั้งได้เองในไม่กี่ขั้นตอน",
        id: 0,
      },
      {
        data: "• ไม่ต้องใช้คีย์เวิร์ด",
        id: 1,
        isShowOnlyDesktop: true,
      },
      {
        data: "• เข้าระบบผ่านแอป deeple Console",
        id: 2,
      },
    ],
  },
  {
    id: 3,
    header: "เชื่อมต่อแพลตฟอร์มได้หลากหลาย",
    imageComponent: (
      <img
        src={Feature4}
        loading="lazy"
        height="60"
        width="60"
        alt=""
        className="mb-7 feature-icon"
      />
    ),
    details: [
      {
        data: "• ใช้งานแชทบอทบน LINE, FACEBOOK และ Instagram",
        id: 0,
      },
      {
        data: "• ระบบขนส่ง SHIPPOP",
        id: 1,
        isShowOnlyDesktop: true,
      },
      {
        data: "• ระบบรับชำระเงิน (Payment Gateway)",
        id: 2,
      },
    ],
  },
];

const FeatureList: React.FC = () => (
  <div className="relative bg-cover my-0 ">
    <div
      className="absolute header-pink-feature-list"
      style={{
        backgroundImage: "url(" + HeaderImage + ")",
      }}
    />
    <div className="card-scroller-container relative feature-list-layout">
      <div className="feature-list-header flex justify-center flex-col items-center relative">
        <h3 className="font-weight-600 text-gradient card-integration-heading pb-2 w-fit-content">
          เริ่มต้นใช้งาน ระบบจัดการร้านค้าออนไลน์ deeple
        </h3>
        <h3 className="card-integration-heading">ทดลองใช้งานฟรี</h3>
        <div className="flex items-center justify-center lg:mt-12 mt-9">
          <a href="https://console.deeple.ai/register">
            <Button
              className="lg:px-10 lg:text-xl text-sm"
              variant="gradient"
              size="medium"
            >
              สมัครใช้งานฟรี
            </Button>
          </a>
        </div>
      </div>
      <div className="absolute white-bg">
        <div className="relative lg:block flex align-center h-full justify-center">
          <div className="relative feature-list-content-layout">
            <div
              className="grid 
                        grid-cols-2 sm:justify-start md:grid-cols-2 lg:grid-cols-4 gap-4 grid-layout"
            >
              {featureListDetails.map((item) => {
                const Image = item.imageComponent;
                return (
                  <div
                    className="lg:mt-12 lg:mb-9 md:mt-8 text-black text-xl md:text-md"
                    key={item.id}
                  >
                    {Image}
                    <h3 className="feature-header">{item.header}</h3>
                    {item.details.map((detail) => {
                      const { isShowOnlyDesktop } = detail;
                      return (
                        <p
                          className={`feature-content ${
                            isShowOnlyDesktop ? "desktop-only" : ""
                          }`}
                          key={detail.id}
                        >
                          {detail.data}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureList;
