import React from "react";
import "./pricing.css";
import Plan from "../../Pricing/Plan";

const Pricing: React.FC = () => {
  return (
    <div className="relative wrapper-pricing">
      <div className="relative">
        <div className="card-scroller-container p-6 pb-8 lg:pb-0 lg:p-0">
          <div className="text-center">
            <h2 className="video-sub-heading-2 mb-1">
              Choose Your deeple Plan
            </h2>
            <h3 className="font-weight-600 text-gradient card-integration-heading lg:pb-8">
              เลือกแพลนที่เหมาะกับธุรกิจของคุณ
            </h3>
          </div>
        </div>
      </div>
      <Plan isShowHeader={false} />
    </div>
  );
};

export default Pricing;
