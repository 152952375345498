import React from "react";
import "../../CardScroller/index.css";
import deepleWhiteThemeLogo from "../../../../static/deeple-logo-bg-white.svg";
type CardPropsType = {
  children?: React.ReactNode;
};

const CardVideo: React.FC<CardPropsType> = () => {
  return (
    <div className="relative card-video-wrapper bg-white">
      <div className="card-scroller-container-video px-6 md:px-0">
        <div>
          <img
            className="deeple-logo"
            src={deepleWhiteThemeLogo}
            alt="deeple advanced AI chatbot"
          />
        </div>
        <h2 className="cards-shelf-main-header video-heading text-gradient">
          เปลี่ยนทุกการซื้อ-ขายของคุณให้ง่ายขึ้น
        </h2>
        <div className="text-custom-mid-gray video-sub-heading-1 mb-6">
          ระบบจัดการร้านค้าออนไลน์ แชทบอทปิดการขาย จัดการออเดอร์ ดูดคอมเมนต์ไลฟ์
          และช่วยขายครบวงจร
        </div>
        <div className="text-custom-mid-gray video-sub-heading-2">
          สำหรับธุรกิจบน Facebook, LINE OA และ Instagram
        </div>
      </div>

      <div className="mx-auto" style={{ maxWidth: "1150px" }}>
        <div className="relative lg:px-0 px-6">
          <div
            className="relative w-full px-0 pb-0 bg-cover"
            style={{
              paddingTop: "56.25%", // 16:9 ratio
              backgroundPosition: "50% 50%",
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/2Sh4HatURkM"
              frameBorder="0"
              className="absolute left-0 top-0 w-full h-full pointer-events-auto"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardVideo;
