import React, { useEffect, useState } from "react";
import "./cardIphone.css";
import SubImage1 from "../../../../static/icon/campaign/card-sub-iphone-1.svg";
import SubImage2 from "../../../../static/icon/campaign/card-sub-iphone-2.svg";
import SubImage3 from "../../../../static/icon/campaign/card-sub-iphone-3.svg";
import SubImage4 from "../../../../static/icon/campaign/card-sub-iphone-4.svg";
import SubImage5 from "../../../../static/icon/campaign/card-sub-iphone-5.svg";
import LottiePlayer from "../../LottiePlayer";
import AiVisualProductSearch from "../../../../static/lottie/deeple-Ai-Visual-Product-Search.json";
import AiAddressRecognition from "../../../../static/lottie/deeple-Ai-Address-Recognition.json";
import AiUpSellIntelligent from "../../../../static/lottie/deeple-Ai-UpSell-Intelligent.json";
import AiiLiveToChat from "../../../../static/lottie/deeple-Ai-Live-to-Chat.json";
import AiIgToChat from "../../../../static/lottie/deeple-AI-Ig-Comment-to-chat.json";
type CardPropsType = {
  children?: React.ReactNode;
};

enum FEATURE_TYPE {
  VirtualProduct = "virtual-product",
  UpSell = "up-sell",
  Address = "address",
  FB = "facebook",
  IG = "instagram",
}

const contentList = [
  {
    label: "ค้นหาสินค้าด้วยรูปภาพ",
    id: "0",
    subLabel: "AI Visual Product Search",
    imageType: FEATURE_TYPE.VirtualProduct,
    videoLink: AiVisualProductSearch,
    subImageComponent: <img className="sub-image" src={SubImage1} />,
  },
  {
    id: "1",
    label: "เสนอโปรโมชั่นอัตโนมัติ",
    subLabel: "AI Upsell Intelligence",
    imageType: FEATURE_TYPE.UpSell,
    videoLink: AiUpSellIntelligent,
    subImageComponent: <img className="sub-image" src={SubImage2} />,
  },
  {
    id: "2",
    label: "อ่านและจดจำที่อยู่อัตโนมัติ",
    subLabel: "AI Address Recognition",
    imageType: FEATURE_TYPE.Address,
    videoLink: AiAddressRecognition,
    subImageComponent: <img className="sub-image" src={SubImage3} />,
  },
  {
    id: "3",
    label: "ดูดคอมเมนต์จาก Facebook",
    subLabel: "FB Live and Comment to Chat",
    imageType: FEATURE_TYPE.FB,
    videoLink: AiiLiveToChat,
    subImageComponent: <img className="sub-image" src={SubImage4} />,
  },
  {
    id: "4",
    label: "ดูดคอมเมนต์ IG Post และตอบกลับ IG Story",
    subLabel: "IG Comment and Story to Chat",
    imageType: FEATURE_TYPE.IG,
    videoLink: AiIgToChat,
    subImageComponent: <img className="sub-image" src={SubImage5} />,
  },
];

const CardImage: React.FC<CardPropsType> = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [videoLink, setVideoLink] = useState<any>(AiVisualProductSearch);
  const [activeIndex, setActiveIndex] = useState(0);
  const [intervalFn, setIntervalFn] = useState<any>();
  const [selectedImage, setSelectedImage] = useState(
    FEATURE_TYPE.VirtualProduct
  );

  useEffect(() => {
    let currentIndex = activeIndex;
    setVideoLink(contentList[currentIndex].videoLink);
    setSelectedImage(contentList[currentIndex].imageType);
    if (intervalFn) {
      clearInterval(intervalFn);
    }
    setIntervalFn(
      setInterval(() => {
        setVideoLink(contentList[currentIndex].videoLink);
        setSelectedImage(contentList[currentIndex].imageType);
        currentIndex =
          currentIndex === contentList.length - 1 ? 0 : currentIndex + 1;
      }, 5000)
    );
  }, [activeIndex]);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="card-iphone-wrapper">
      <div className="card-scroller-container">
        <div className="lg:flex lg:gap-12">
          <div>
            <div className="mobile-iphone-header lg:px-0 px-5 pb-12">
              <h2 className="video-sub-heading-2 mb-1">
                deeple Advanced AI Technology
              </h2>
              <h3 className="font-weight-600 text-gradient card-integration-heading pb-2 w-fit-content">
                เทคโนโลยี AI
              </h3>
              <h3 className="card-integration-heading">
                ที่ยกระดับประสบการณ์ การซื้อ-ขายอย่างแตกต่าง
              </h3>
            </div>
            <div className="card-content-iphone-wrapper">
              {contentList.map((item, index) => {
                const Image = item.subImageComponent;
                return (
                  <div
                    key={item.imageType}
                    onClick={() => handleClick(index)}
                    style={{
                      ...(selectedImage === item.imageType
                        ? {
                            background: `linear-gradient(
                      90deg,
                      #8632b1 8.49%,
                      #e43491 40.99%,
                      #e85b4e 73.5%
                    )`,
                          }
                        : { background: "transparent" }),
                      borderRadius: "20px",
                      padding: "3px",
                    }}
                  >
                    <div className="card-iphone-content">
                      {Image}
                      <div className="wrapper-label">
                        <div className="text-center leading-5 card-sub-label">
                          {item.subLabel}
                        </div>
                        <div className="text-center leading-5 card-label">
                          {item.label}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lg:hidden dp-cards-scroller dp-card-iphone-height">
            <div className="dp-cards-scroller-crop-image">
              <div className="dp-cards-scroller-content">
                <div className="dp-cards-scroller-platter-mobile">
                  {contentList.map((item) => {
                    const Image = item.subImageComponent;
                    return (
                      <div
                        key={item.imageType}
                        className="dp-cards-scroller-item-view-mobile"
                      >
                        <div className="card-image-scroller-iphone">
                          <div className="flex justify-center pt-8">
                            <LottiePlayer
                              file={item.videoLink}
                              height={432}
                              width={228}
                              controllable={false}
                              defaultPlay
                            />
                          </div>
                          <div className="card-image-scroller-content">
                            {Image}
                            <div>
                              <div className="card-sub-label">
                                {item.subLabel}
                              </div>
                              <div className="text-2xl leading-7">
                                {item.label}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="lottie-layout lg:px-0 px-5">
            <div>
              <LottiePlayer
                file={videoLink}
                height={680}
                width={360}
                controllable={false}
                defaultPlay
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardImage;
